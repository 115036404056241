import React from 'react';
import _ from "lodash";

import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import FindOut from "gatsby-theme-starberry-lomondgroup/src/components/FindOut/FindOut";
import Info from "gatsby-theme-starberry-lomondgroup/src/components/Info/Info"
import Features from "gatsby-theme-starberry-lomondgroup/src/components/Features/Features";
import Customer from "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer";
import TextImageBlock from "gatsby-theme-starberry-lomondgroup/src/components/TextImageBlock/TextImageBlock";
import Reviews from "gatsby-theme-starberry-lomondgroup/src/components/Reviews/Reviews";
import VideoReviews from "gatsby-theme-starberry-lomondgroup/src/components/Reviews/VideoReviews";
import News from "gatsby-theme-starberry-lomondgroup/src/components/News/News"
import Journey from "gatsby-theme-starberry-lomondgroup/src/components/Journey/Journey";
import Banner from "gatsby-theme-starberry-lomondgroup/src/components/Banner/Banner"
import Counter from "gatsby-theme-starberry-lomondgroup/src/components/Counter/Counter";
import Team from "gatsby-theme-starberry-lomondgroup/src/components/Team/Team"
import LocalExperience from "gatsby-theme-starberry-lomondgroup/src/components/LocalExperience/LocalExperience"
import LinkGroup from "gatsby-theme-starberry-lomondgroup/src/components/LinkGroup/LinkGroup"
import ManagementServices from "gatsby-theme-starberry-lomondgroup/src/components/ManagementServices/ManagementServices"
import FeaturedLogo from "gatsby-theme-starberry-lomondgroup/src/components/FeaturedLogo/FeaturedLogo";
import BannerSlider from "gatsby-theme-starberry-lomondgroup/src/components/BannerSlider/Slider";
import TilesBlock from "gatsby-theme-starberry-lomondgroup/src/components/TilesBlock/Tiles";
import ServiceTiles from "gatsby-theme-starberry-lomondgroup/src/components/TilesBlock/ServiceTiles";
import TrustpilotReviews from "gatsby-theme-starberry-lomondgroup/src/components/TrustpilotReviews/TrustpilotReviews";


const PageTemplate = (props) =>{


  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;

  var isJourneySec = _.find(GQLModules, function (o) { return o.__typename === "GLSTRAPI_ComponentComponentsShowPropertyJourney" && o.show });

  //console.log("GQLModules ==>", GQLModules);

  return(
    <Layout seo_meta={GQLPage.seo_meta} isJourneySec={isJourneySec} menu_slug={GQLPage?.menu?.slug} popular_search={GQLPage?.menu?.popular_search} GQLPage={GQLPage}>
      
        {GQLPage?.banner_section?.banner_image && <Banner banner_section = {GQLPage.banner_section} GQLPage={GQLPage}/> }

        {GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
            return(
                <>
                    {(!GQLPage?.banner_section?.banner_image && Module?.__typename === "GLSTRAPI_ComponentComponentsSliderComponent") && <BannerSlider key={Module} Module={Module} GQLPage={GQLPage}/> }

                    {Module?.__typename === "GLSTRAPI_ComponentComponentsClientLogosComponent" && <FeaturedLogo key={Module} Module={Module} /> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsIntroSection" && <FindOut key={Module} Module={Module} /> }                    

                    {Module.__typename === "GLSTRAPI_ComponentComponentsHomeTilesComponent" && <Info key={Module} Module={Module} GQLPage={GQLPage}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsTrustpilotComponent" && <TrustpilotReviews key={Module} Module={Module} />}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" && Module.show && <Features key={Module} Module={Module} menu_slug={GQLPage?.menu?.slug}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsVideoSection" &&  <Customer key={Module} Module={Module} GQLPage={GQLPage}/>}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsTextBlockWithImage" &&  <TextImageBlock key={Module} Module={Module} GQLPage={GQLPage}/>}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowStatisticsSection" && Module.show &&  <Counter key={Module} Module={Module} />}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowMeetTeam" && Module.show &&  <Team key={Module} Module={Module} category={Module.team_department} />}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsLocalExperience" && <LocalExperience key={Module} Module={Module} GQLPage={GQLPage}/>}

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowCustomerReviews" && Module.show && <Reviews key={Module} Module={Module}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsCustomerVideoReviews" && Module?.customer_video_review.length > 0 && <VideoReviews key={Module} Module={Module?.customer_video_review[0]} GQLPage={GQLPage}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowNewsInsight" && Module.show && <News key={Module} Module={Module}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsPropertyServiceManagement" && <ManagementServices key={Module} Module={Module}/> }

                    {Module.__typename === "GLSTRAPI_ComponentComponentsWhatAreYouLookingFor" && <LinkGroup key={Module} Module={Module}/> }
                    

                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowPropertyJourney" && Module.show && <Journey key={Module} />}
                </>                
            )
        })}
                
    </Layout>
  )

}

export default PageTemplate

